
	frappe.templates['warehouse_capacity_summary_header'] = `<div class="dashboard-list-item" style="padding: 12px 15px;">
	<div class="row">
		<div class="col-sm-2 text-muted" style="margin-top: 8px;">
			{{ __("Warehouse") }}
		</div>
		<div class="col-sm-2 text-muted" style="margin-top: 8px;">
			{{ __("Item") }}
		</div>
		<div class="col-sm-1 text-muted" style="margin-top: 8px;">
			{{ __("Stock Capacity") }}
		</div>
		<div class="col-sm-2 text-muted" style="margin-top: 8px;">
			{{ __("Balance Stock Qty") }}
		</div>
		<div class="col-sm-2 text-muted" style="margin-top: 8px;">
			{{ __("% Occupied") }}
		</div>
	</div>
</div>
`;
